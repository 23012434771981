import React from "react";
import { useStaticQuery, graphql } from 'gatsby';

import SEO from "../components/seo";
import IndustriesList from '../containers/IndustriesList';

const Industries = () => {

  const data = useStaticQuery(graphql`
    query {
      dataJson {
        INDUSTRIES {
          title
          description
          items {
            id
            color
            icon {
              publicURL
            }
            title
            description
          }
          seo {
            title
            description
            url
            imageAlt
            twitterProfile
            image {
              publicURL
            }
            twiterImage {
              publicURL
            }
            keywords
          }
        }
      }
    }
  `);
  const { title, description, items, seo } = data.dataJson.INDUSTRIES;
  
  return(
    <>
      <SEO metaData={seo} lang="" />
      <IndustriesList 
        title={title}
        description={description}
        items={items}
      />
    </>
  );
    
}

export default Industries
