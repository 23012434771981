import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import React, { Fragment } from 'react';
import { plus } from 'react-icons-kit/typicons/plus';
import { starOutline } from 'react-icons-kit/typicons/starOutline';
import { mediaRecordOutline } from 'react-icons-kit/typicons/mediaRecordOutline';

import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Image from '../../common/components/Image';
import Container from '../../common/components/UI/Container';
import FeatureBlock from '../../common/components/FeatureBlock';

import SectionWrapper, { FeatureWrapper } from './industries.style';

const InduestriesList = ({ 
  titleStyle, 
  descriptionStyle, 
  linkStyle, 
  headingStyle, 
  showMoreStyle,
  title, 
  description, 
  items,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
}) => {

  return (
    <SectionWrapper>
      <Container>
        
        <Box {...sectionHeader}>
          <Heading {...sectionTitle} content={title} />
          <Text
            {...sectionSubTitle}
            content={description}
          />
        </Box>

        <FeatureWrapper>
          {items.map((item) => (
            <Fade up delay={100 * item.id} key={`feature-key${item.id}`}>
              <Link to={item.id}>
                <FeatureBlock
                  style={{ '--color': `${item.color}` }}
                  icon={
                    <Fragment>
                      <Icon className="plus" icon={plus} />
                      <Icon className="circle" icon={mediaRecordOutline} />
                      <Image src={item.icon.publicURL} alt={item.title} />
                      <Icon className="star" icon={starOutline} />
                    </Fragment>
                  }
                  iconPosition="left"
                  title={<Heading as="h3" content={item.title} {...titleStyle} />}
                  description={<Text content={item.description} {...descriptionStyle} />}
                />
              </Link>
            </Fade>
          ))}
        </FeatureWrapper>

      </Container>
    </SectionWrapper>
  );
};

InduestriesList.propTypes = {
  titleStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  showMoreStyle: PropTypes.object,
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,  
};

InduestriesList.defaultProps = {
  titleStyle: {
    textAlign: 'left',
    paddingLeft: '10px',
    fontWeight: 'bold'
  },
  descriptionStyle: {
    textAlign: 'left',
    marginBottom: '20px'
  },
  headingStyle: {
    marginTop: '15px'
  },
  showMoreStyle: {
    paddingTop: '10px !important',
    textAlign: 'left',
    fontWeight: 'bold'
  },
  sectionHeader: {
    mt: '50px',
    mb: '56px',
    ml: '5%',
    mr: '5%'
  },
  sectionTitle: {
    textAlign: 'center',
    paddingBottom: '20px',
    fontSize: ['35px', '28px', '31px', '35px', '43px'],
    fontWeight: 'bold',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  sectionSubTitle: {
    textAlign: 'center',
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    mb: '0',

  },
}

export default InduestriesList;
